import { applyMiddleware, createStore } from 'redux';
import { thunk, withExtraArgument } from 'redux-thunk';
import actions from './actions';
import reducers from './reducers';

export default function store() {
    // Create a thunk middleware with the extra argument
    const thunkWithExtraArgument = withExtraArgument(actions);
    
    // Apply the middleware to the store
    const _store = createStore(reducers, applyMiddleware(thunkWithExtraArgument));
    
    // logging
    /*
    _store.subscribe(()=>{
        console.log(_store.getState());
    });
    */
    return _store;
}

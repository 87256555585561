import React, { Component } from 'react';
import Preloader from '../Preloader'
import { Card, Table } from 'react-bootstrap'
import moment from 'moment-timezone'
import { evaluateCardinalDirection } from '../../lib/graph-util';
import { format } from 'date-fns';

export default class CurrentStatus extends Component {
    getReadingAge = (timestamp) => {
        if (!timestamp) return null;
        const now = new Date();
        const readingTime = new Date(timestamp);
        return Math.floor((now - readingTime) / (1000 * 60)); // Age in minutes
    };

    getAgeClass = (age) => {
        if (age > 20) return 'caution';
        if (age > 10) return 'note';
        return '';
    };

    createTooltip(v) {
        return v
    }

    render() {
        const { summary } = this.props;        
        if (summary && summary.LastObservationUTC) {
            const { min1: latest } = summary;
            const timestamp = summary.LastObservationUTC;
            const age = this.getReadingAge(Number(timestamp));
            const ageClass = this.getAgeClass(age);
            const formattedTime = format(new Date(Number(timestamp)), 'h:mm a');
            const hasGusts = latest.maxSpeed !== undefined && latest.maxSpeed !== null;
            
            return (
                <div className="current-conditions">
                    <div className={`current-time ${ageClass}`}>
                        <span className="current-time-label">Last updated</span>
                        <span className="current-time-value">{formattedTime}</span>
                        {age !== null && (
                            <span className="age-counter">
                                ({age} {age === 1 ? 'minute' : 'minutes'} ago)
                            </span>
                        )}
                    </div>
                    <div className={`current-values ${hasGusts ? 'three-columns' : 'two-columns'}`}>
                        <div className="current-value">
                            <div className="current-label">Wind Speed</div>
                            <div className="current-number">{latest.averageSpeed}</div>
                            <div className="current-unit">knots</div>
                        </div>
                        {hasGusts && (
                            <div className="current-value">
                                <div className="current-label">Gusts</div>
                                <div className="current-number">{latest.maxSpeed}</div>
                                <div className="current-unit">knots</div>
                            </div>
                        )}
                        <div className="current-value">
                            <div className="current-label">Direction</div>
                            <div className="current-direction">
                                <span className="direction-cardinal">{evaluateCardinalDirection(latest.averageDirection)}</span>
                                <span className="direction-degrees">({latest.averageDirection}°)</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (summary) {
            return <React.Fragment/>
        } else {
            return <Preloader/>
        }
    }
}
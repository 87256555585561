import React, { Component, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ScatterGraph from '../components/StationLayout/ScatterGraph';
import CurrentStatus from '../components/StationLayout/CurrentStatus';
import WebCams from '../components/StationLayout/WebCams';
import Preloader from '../components/Preloader';
import Header from '../components/Header';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { getStationHistory } from '../lib/api';
import { format } from 'date-fns';

import './StationLayout.css';

const StationLayoutWrapper = () => {
  const { id, type } = useParams();
  const [data, setData] = useState(undefined);

  useEffect(() => {
    const loadData = async () => {
      const stationData = await getStationHistory(id, type, 'Full');
    
      if (
        stationData && 
        stationData.stationDetails && 
        stationData.current && 
        stationData.weather
      ) {
        setData(stationData);
      } else {
        console.error('Data received from server is not in the expected format:', stationData);
      }
    };
    loadData();
    const interval = setInterval(loadData, 60000);
  
    return () => {
      clearInterval(interval);
    };
  }, [id, type]);

  if (!data) {
    return <Preloader style={{ marginTop: 300 }} />;
  }

  return <StationLayout data={data} stationType={type} />;
};

class StationLayout extends Component {
  getReadingAge = (timestamp) => {
    if (!timestamp) return null;
    const now = new Date();
    const readingTime = new Date(timestamp);
    return Math.floor((now - readingTime) / (1000 * 60)); // Age in minutes
  };

  getAgeWarning = (timestamp) => {
    const age = this.getReadingAge(timestamp);
    if (!age) return null;

    if (age > 20) {
      return (
        <div className="age-warning caution">
          This reading is more than 20 minutes old
        </div>
      );
    } else if (age > 10) {
      return (
        <div className="age-warning note">
          This reading is more than 10 minutes old
        </div>
      );
    }
    return null;
  };

  render() {
    const { data, stationType } = this.props;
    const getStationClass = (type) => {
      switch(type.toLowerCase()) {
        case 'bom':
          return 'bom-station';
        case 'unique_vicports':
          return 'vicports-station';
        default:
          return '';
      }
    };
    const stationClass = getStationClass(stationType);
    const timestamp = data.current?.timestamp;
    const formattedTime = timestamp ? format(new Date(timestamp), 'h:mm a') : '';
    
    return (
      <div>
        <Header/>
        <div className={`station-page ${stationClass}`}>
          <div className="station-header">
            <div className="station-title">
              <h1>{data.stationDetails.NM}</h1>
            </div>
            <div className="station-logo">
              <img src={`/station_logos/${data.stationDetails.Logo}`} alt="Station logo"/>
            </div>
          </div>

          {this.getAgeWarning(timestamp)}

          <div className="station-card">
            <div className="station-card-body">
              <CurrentStatus summary={data.current} />
            </div>
          </div>

          <div className="station-card wind-trend">
            <div className="station-card-body">
              <ScatterGraph summary={data.weather} />
            </div>
          </div>

          {data.stationDetails.WebCam && (
            <div className="station-card">
              <div className="station-card-header">
                <h2>Webcams</h2>
              </div>
              <div className="station-card-body">
                <WebCams summary={data.stationDetails} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StationLayoutWrapper;

import React, { Component } from 'react';
import { loadMap, loadFeatures, registerEvent } from '../lib/maps';
import { selectStation, setStationHistory, reset, dispatchUrls } from '../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import Popup from '../components/Popup';
import Header from '../components/Header';
import './Layout.css';
import { getUrls } from '../lib/api';

const mapId = 'map', popupId = 'popup';

class Layout extends Component {
    state = {}
    componentDidMount = async () => {
        this.map = loadMap(mapId);

        registerEvent(this.map,
            this.props.selectStation.bind(this),
            this.props.setStationHistory.bind(this),
            this.props.reset.bind(this),
            popupId);

        // init load
        await load(this.map, this.props);

        // reloads features every minute
        this.setState({interval: setInterval(this.poll.bind(this), 60000)});
    }

    componentWillUnmount = () => { clearInterval(this.state.interval); }

    poll = async () => { await load(this.map, this.props) }

    onCloseDetails = () => { this.setState({openDetails:false})}

    render = () => {
        const { station, history } = this.props
        return (
            <div style={{ height: '100%', width: '100%', position: 'fixed' }}>
                <Header/>
                <div style={{ 
                    position: 'absolute',
                    top: '70px',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    backgroundColor: '#f8f9fa'
                }} id={mapId} />
                <div id={popupId} className="ol-popup" onMouseUp={convertToClick}>
                    <Popup station={station} history={history}/>
                </div>
            </div>
        );
    }
}

const initializeFeatures = async (map, dispatchUrls) => {
    const urls = await getUrls();
    if (urls.status === undefined || urls.status !== 500) {
        dispatchUrls(urls);
        loadFeatures(map, urls);
    } else if (urls.status === 500) {
        console.log(`WARNING: problem fetching urls ${urls.message}`);
    }
}

const load = async (map, props) => {
    const { urls, urlsTimestamp, dispatchUrls } = props;

    if (urls) {
        const diff = new Date().getTime() - urlsTimestamp;
        // timestamp
        if (0 === urlsTimestamp || diff > (60000 * 2)) { // TEMP: after 2 minutes
            console.log('refreshing signed urls');
            await initializeFeatures(map, dispatchUrls);
        } else {
            loadFeatures(map, urls)
        }
    } else {
        await initializeFeatures(map, dispatchUrls);
    }
}

// fix to enable firing of onClick events on openlayers popups
const convertToClick = (e) => {
    const evt = new MouseEvent('click', { bubbles: true })
    evt.stopPropagation = () => {}
    e.target.dispatchEvent(evt)
}

const mapStateToProps = (state) => {
    return {
        station: state.station.station,
        history: state.station.history,
        urls: state.feature.urls,
        urlsTimestamp: state.feature.urlsTimestamp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectStation: bindActionCreators(selectStation, dispatch),
        setStationHistory: bindActionCreators(setStationHistory, dispatch),
        reset: bindActionCreators(reset, dispatch),
        dispatchUrls: bindActionCreators(dispatchUrls, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import React, { Component } from 'react';
import Preloader from '../Preloader'
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    TimeScale,
    CategoryScale,
    Filler
} from 'chart.js';
import { Line, Scatter } from 'react-chartjs-2';
import './Scatter.css';
import { evaluateCardinalDirection, getWindSpeedGradient, getFilteredData } from '../../lib/graph-util';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';

ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    TimeScale,
    CategoryScale,
    Filler
);

const EMPTY_CHART_DATA = {
    datasets: []
};

export default class ScatterGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            windSpeedData: EMPTY_CHART_DATA,
            windDirectionData: EMPTY_CHART_DATA
        };
    }

    componentDidMount() {
        this.updateChartData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.summary !== this.props.summary) {
            this.updateChartData();
        }
    }

    updateChartData() {
        
        const { summary } = this.props;
        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            console.log('No valid summary data');
            this.setState({
                isLoading: false,
                windSpeedData: EMPTY_CHART_DATA,
                windDirectionData: EMPTY_CHART_DATA
            });
            return;
        }

        try {
            const canvas = document.createElement('canvas');
            const timestamps = summary.map(item => new Date(item.UTC));
            
            // Get the data first for debugging
            const avgData = getFilteredData(summary, 'S');
            const gustData = getFilteredData(summary, 'SX');
            const dirData = getFilteredData(summary, 'D');
            
            
            const windSpeedData = {
                datasets: [
                    {
                        label: 'Average',
                        data: avgData,
                        fill: true,
                        tension: 0.3,
                        borderColor: 'transparent',
                        borderWidth: 1,
                        backgroundColor: getWindSpeedGradient(canvas, avgData, gustData),
                        pointRadius: 0,
                        pointHitRadius: 10,
                        showLine: true
                    },
                    {
                        label: 'Gust',
                        data: gustData,
                        fill: false,
                        tension: 0.3,
                        borderColor: '#505050',
                        borderDash: [10, 5],
                        borderWidth: 2,
                        pointRadius: 0,
                        pointHitRadius: 10,
                        showLine: true
                    }
                ]
            };

            // Process wind direction data
            const windDirectionData = {
                datasets: [
                    {
                        label: 'Wind Direction',
                        data: dirData,
                        fill: false,
                        tension: 0.3,
                        borderColor: '#848484',
                        borderWidth: 2,
                        pointRadius: 0,
                        pointHitRadius: 10,
                        showLine: true
                    }
                ]
            };

            

            this.setState({
                isLoading: false,
                windSpeedData,
                windDirectionData
            });
        } catch (error) {
            console.error('Error processing chart data:', error);
            this.setState({
                isLoading: false,
                windSpeedData: EMPTY_CHART_DATA,
                windDirectionData: EMPTY_CHART_DATA
            });
        }
    }

    render() {
        const { summary } = this.props;
        const { isLoading, windSpeedData, windDirectionData } = this.state;
        
        if (isLoading) {
            return <Preloader/>;
        }

        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            return <div>No data available.</div>;
        }

        const commonOptions = {
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            interaction: {
                intersect: false,
                mode: 'index'
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        title: (context) => {
                            return format(new Date(context[0].raw.x), 'HH:mm');
                        },
                        label: (context) => {
                            const datasetLabel = context.dataset.label;
                            const value = context.raw.y;
                            const directionValue = windDirectionData.datasets[0].data.find(
                                d => d.x === context.raw.x
                            )?.y;
                            
                            if (datasetLabel === 'Wind Direction') {
                                return `Direction: ${value}° ${evaluateCardinalDirection(value)}`;
                            }
                            
                            const lines = [
                                `${datasetLabel}: ${value} knots`,
                            ];
                            
                            if (directionValue !== undefined && context.datasetIndex === 0) {
                                lines.push(`Direction: ${directionValue}° ${evaluateCardinalDirection(directionValue)}`);
                            }
                            
                            return lines;
                        }
                    }
                }
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'hour',
                        stepSize: 1,
                        displayFormats: {
                            hour: 'HH:mm'
                        }
                    },
                    grid: {
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: true
                    },
                    ticks: {
                        font: {
                            size: 11
                        }
                    }
                }
            }
        };

        const WindSpeedOptions = {
            ...commonOptions,
            scales: {
                ...commonOptions.scales,
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Wind Speed (Knots)'
                    },
                    ticks: {
                        stepSize: 5,
                        font: { size: 11 }
                    }
                }
            }
        };

        const WindDirectionOptions = {
            ...commonOptions,
            scales: {
                ...commonOptions.scales,
                y: {
                    min: summary[summary.length - 1]?.D > 270 || summary[summary.length - 1]?.D < 90 ? -180 : 0,
                    max: summary[summary.length - 1]?.D > 270 || summary[summary.length - 1]?.D < 90 ? 180 : 360,
                    ticks: {
                        stepSize: 90,
                        callback: function(value) {
                            const directions = {
                                0: 'N', 90: 'E', 180: 'S', 270: 'W',
                                360: 'N', '-180': 'S', '-90': 'E'
                            };
                            return directions[value] || '';
                        },
                        font: { size: 11 }
                    },
                    title: {
                        display: true,
                        text: 'Wind Direction'
                    }
                }
            }
        };

        return (
            <>
                <div style={{height: '180px', marginBottom: '10px'}}>
                    <Scatter 
                        data={windSpeedData} 
                        options={WindSpeedOptions}
                        fallbackContent={<div>Unable to load wind speed chart</div>}
                    />
                </div>
                <div style={{height: '180px'}}>
                    <Scatter 
                        data={windDirectionData} 
                        options={WindDirectionOptions}
                        fallbackContent={<div>Unable to load wind direction chart</div>}
                    />
                </div>
            </>
        );
    }
}
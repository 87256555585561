import React from 'react';
import { createRoot } from 'react-dom/client';

import Routes from './routes';

import { Provider } from 'react-redux'
import createStore from './store'

import './index.css'

const store = createStore();

// Create a root
const root = createRoot(document.getElementById('root'));

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('ServiceWorker registration successful');
      })
      .catch(err => {
        console.log('ServiceWorker registration failed: ', err);
      });
  });
}

root.render(
  <Provider store={store}>
    <Routes/>
  </Provider>
);

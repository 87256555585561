import React, { Component } from 'react';
import Preloader from './Preloader'
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    TimeScale
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Scatter } from 'react-chartjs-2';
import './Scatter.css';
import './Header.css';
import 'chartjs-adapter-date-fns';
import { getFilteredData, getWindSpeedGradient } from '../lib/graph-util';

ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    TimeScale,
    annotationPlugin
);

const EMPTY_CHART_DATA = {
    datasets: []
};

export default class ScatterGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            chartData: EMPTY_CHART_DATA
        };
    }

    componentDidMount() {
        this.updateChartData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.summary !== this.props.summary) {
            this.updateChartData();
        }
    }

    updateChartData() {
        const { summary } = this.props;
        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            this.setState({
                isLoading: false,
                chartData: EMPTY_CHART_DATA
            });
            return;
        }

        try {
            const average = getFilteredData(summary, 'S') || [];
            const max = getFilteredData(summary, 'SX') || [];
            const canvas = document.createElement('canvas');
            const gradient = getWindSpeedGradient(canvas, average, max);

            const chartData = {
                datasets: [
                    {
                        label: 'Gust',
                        data: max,
                        fill: false,
                        borderColor: '#505050',
                        borderDash: [10, 5],
                        borderWidth: 2,
                        pointRadius: 0,
                        pointHitRadius: 10,
                        showLine: true
                    },
                    {
                        label: 'Average',
                        data: average,
                        fill: true,
                        borderColor: 'transparent',
                        borderWidth: 1,
                        backgroundColor: gradient,
                        pointRadius: 0,
                        pointHitRadius: 10,
                        showLine: true
                    }
                ]
            };

            this.setState({
                isLoading: false,
                chartData
            });
        } catch (error) {
            console.error('Error processing chart data:', error);
            this.setState({
                isLoading: false,
                chartData: EMPTY_CHART_DATA
            });
        }
    }

    render() {
        const { summary } = this.props;
        const { isLoading, chartData } = this.state;

        if (isLoading) {
            return <Preloader/>;
        }

        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            return <div>No data available</div>;
        }
        
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            interaction: {
                intersect: false,
                mode: 'index'
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        title: function(context) {
                            const time = new Date(context[0].parsed.x);
                            return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        },
                        label: function(context) {
                            return `${context.dataset.label}: ${context.parsed.y} knots`;
                        }
                    },
                    titleFont: {
                        weight: '600'
                    },
                    padding: 8,
                    displayColors: false
                }
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'hour',
                        stepSize: 1,
                        displayFormats: {
                            hour: 'HH:mm'
                        },
                        tooltipFormat: 'HH:mm'
                    },
                    display: true,
                    grid: {
                        display: true,
                        drawOnChartArea: false,
                        drawTicks: true,
                        tickLength: 10
                    },
                    ticks: {
                        font: { size: 11 },
                        maxRotation: 0,
                        autoSkip: false
                    }
                },
                y: {
                    beginAtZero: true,
                    grid: {
                        color: 'rgba(0, 0, 0, 0.1)'
                    },
                    ticks: {
                        font: { size: 11 },
                        stepSize: 5
                    }
                }
            }
        };

        return (
            <div style={{ height: '200px', marginTop: '10px' }}>
                <Scatter data={chartData} options={options} />
            </div>
        );
    }
}
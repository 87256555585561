import React from 'react';
import { Card, Image } from 'react-bootstrap';

export default class WebCams extends React.Component {
    render() {
        const { webcams } = this.props;
        if (webcams && webcams.length > 0) {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title as="h2">WebCams</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {webcams.map((webcam, i) => (
                            <Image key={i} src={webcam.url} fluid />
                        ))}
                    </Card.Body>
                </Card>
            );
        } else {
            return null;
        }
    }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Popup.css'
import { Card, Table, Image } from 'react-bootstrap'
import moment from 'moment-timezone'
import templates from '../assets/templates.json'
import ScatterGraph from './ScatterGraph'
import Preloader from './Preloader'

export default class Popup extends Component {

    evaluateCardinalDirection(angle) {
      if (typeof angle === 'string') angle = parseInt(angle, 10);

      if (angle <= 0 || angle > 360 || typeof angle === 'undefined') return '☈';
      const arrows = { north: 'N ↓', north_east: 'NE ↙', east: 'E ←', south_east: 'SE ↖', south: 'S ↑', south_west: 'SW ↗', west: 'W →', north_west: 'NW ↘' };
      const directions = Object.keys(arrows);
      const degree = 360 / directions.length;
      angle = angle + degree / 2;
      for (let i = 0; i < directions.length; i++) {
        if (angle >= (i * degree) && angle < (i + 1) * degree) return arrows[directions[i]];
      }
      return arrows['north']; //Catch all
    }

    rows(weather) {
        let trs = [];
        templates.popup.sheet.forEach((sheet,c)=>{
            const val = weather[sheet.value.value];
            if (val) {
                trs.push(
                    <tr key={c}>
                        <th>{sheet.label}</th>
                        <td>{val}{sheet.value.suffix}</td>
                    </tr>
                );
            }
        })
        return trs;
    }

    popup(station, history) {
        const details = station.stationDetails,
            weather = station.weather[0],
            link = `/station/${details.STN}/${details.TYP.toLowerCase()}`;
        
        const getStationClass = (type) => {
            switch(type) {
                case 'BOM':
                    return 'bom-station';
                case 'unique_vicports':
                    return 'vicports-station';
                default:
                    return '';
            }
        };

        const getReadingAge = (timestamp) => {
            const readingTime = new Date(timestamp);
            const now = new Date();
            return Math.floor((now - readingTime) / (1000 * 60)); // Returns minutes
        };
        
        const logoSrc = details.TYP === 'BOM' ? '/station_logos/bom.png' : `/station_logos/${String(details.Logo)}`;
        const stationClass = getStationClass(details.TYP);
        const readingAge = getReadingAge(weather['UTC']);
        
        return (
            <div className={`station-popup ${stationClass}`}>
                <Card>
                    <Card.Header>
                        <div className="popup-header">
                            <div className="popup-title">
                                <h2>{details.NM}</h2>
                            </div>
                            <div className="popup-logo">
                                <Image src={logoSrc} alt={details.NM} />
                            </div>
                        </div>
                    </Card.Header>
                    <div className={`current-time ${readingAge > 20 ? 'caution' : readingAge > 10 ? 'note' : ''}`}>
                        <span className="current-time-label">Last updated</span>
                        <span className="current-time-value">{moment(new Date(weather['UTC'])).format('h:mm a')}</span>
                        {readingAge !== null && (
                            <span className="age-counter">
                                ({readingAge} {readingAge === 1 ? 'minute' : 'minutes'} ago)
                            </span>
                        )}
                    </div>
                    <Card.Body className="popup-body">
                        <div className={`wind-summary ${weather.SX ? 'three-columns' : 'two-columns'}`}>
                            <div className="wind-value">
                                <span className="wind-label">Wind Speed</span>
                                <span className="wind-number">{weather.S} kts</span>
                            </div>
                            {weather.SX && (
                                <div className="wind-value">
                                    <span className="wind-label">Gusts</span>
                                    <span className="wind-number">{weather.SX} kts</span>
                                </div>
                            )}
                            <div className="wind-value">
                                <span className="wind-label">Direction</span>
                                <span className="wind-number">{this.evaluateCardinalDirection(weather.D)}</span>
                            </div>
                        </div>
                        <ScatterGraph summary={history}/>
                        <Link to={link} className="view-details-link">View Details</Link>
                    </Card.Body>
                </Card>
            </div>
        )
    }

    render() {
        const { station, history } = this.props;

        if (station) {
            return this.popup(station, history)
        } else {
            return (
                <Card>
                    <Card.Body>
                        <Preloader/>
                    </Card.Body>
                </Card>
            )
        }
    }
}

const styles = {
    imgDiv: {
        width: '27%',
        position: 'absolute',
        right: '3%',
        top: 5,
        height: 50
    },
    headerDiv: {
        position:'relative',
        width: '70%'
    }
}
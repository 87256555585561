import React, { Component } from 'react';
import {Scatter} from 'react-chartjs-2';
import {Line} from 'react-chartjs-2';
import './Scatter.css';
import Preloader from '../Preloader'
import { Card } from 'react-bootstrap'
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    TimeScale,
    CategoryScale
} from 'chart.js';

import { evaluateCardinalDirection, getFilteredData, getWindSpeedGradient } from '../../lib/graph-util';

ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    TimeScale,
    CategoryScale
);

const EMPTY_CHART_DATA = {
    datasets: [],
    labels: []
};

export default class ScatterGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            chartData: {
                tempHumidity: EMPTY_CHART_DATA,
                windSpeed: EMPTY_CHART_DATA,
                windDirection: EMPTY_CHART_DATA,
                solarIrradiance: EMPTY_CHART_DATA,
                rain: EMPTY_CHART_DATA,
                pressure: EMPTY_CHART_DATA
            }
        };
    }

    componentDidMount() {
        this.updateChartData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.summary !== this.props.summary) {
            this.updateChartData();
        }
    }

    updateChartData() {
        const { summary } = this.props;
        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            this.setState({
                isLoading: false,
                chartData: {
                    tempHumidity: EMPTY_CHART_DATA,
                    windSpeed: EMPTY_CHART_DATA,
                    windDirection: EMPTY_CHART_DATA,
                    solarIrradiance: EMPTY_CHART_DATA,
                    rain: EMPTY_CHART_DATA,
                    pressure: EMPTY_CHART_DATA
                }
            });
            return;
        }

        try {
            this.setState({
                isLoading: false,
                chartData: {
                    tempHumidity: this.getTempHumidityData(),
                    windSpeed: this.getWindSpeedData(),
                    windDirection: this.getWindDirectionData(),
                    solarIrradiance: this.getSolarIrradianceData(),
                    rain: this.getRainData(),
                    pressure: this.getPressureData()
                }
            });
        } catch (error) {
            console.error('Error updating chart data:', error);
            this.setState({
                isLoading: false,
                chartData: {
                    tempHumidity: EMPTY_CHART_DATA,
                    windSpeed: EMPTY_CHART_DATA,
                    windDirection: EMPTY_CHART_DATA,
                    solarIrradiance: EMPTY_CHART_DATA,
                    rain: EMPTY_CHART_DATA,
                    pressure: EMPTY_CHART_DATA
                }
            });
        }
    }

    getTempHumidityData() {
        const { summary } = this.props;
        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            return EMPTY_CHART_DATA;
        }

        try {
            const temperature = getFilteredData(summary, 'T') || [];
            const humidity = getFilteredData(summary, 'RH') || [];
            const timestamps = summary.map(item => item.UTC);
            if (!timestamps.length) return EMPTY_CHART_DATA;

            const canvas = document.createElement('canvas');
            const gradient = getWindSpeedGradient(canvas, temperature, temperature);

            return {
                labels: timestamps,
                datasets: [{
                    fill: false,
                    tension: 0.3,
                    borderColor: '#505050',
                    borderDash: [10,5],
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    data: humidity.map((y, i) => ({ x: timestamps[i], y: y || 0 })),
                    showLine: true,
                    yAxisID: 'y2',
                    label: "Relative Humidity"
                },{
                    fill: true,
                    tension: 0.3,
                    borderColor: 'transparent',
                    borderWidth: 1,
                    backgroundColor: gradient,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    data: temperature.map((y, i) => ({ x: timestamps[i], y: y || 0 })),
                    showLine: true,
                    yAxisID: 'y1',
                    label: "Temperature"
                }]
            };
        } catch (error) {
            console.error('Error generating temperature/humidity data:', error);
            return EMPTY_CHART_DATA;
        }
    }

    getWindDirectionData() {
        const { summary } = this.props;
        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            return EMPTY_CHART_DATA;
        }

        try {
            const direction = getFilteredData(summary, 'D') || [];
            const timestamps = summary.map(item => item.UTC);
            if (!timestamps.length) return EMPTY_CHART_DATA;

            return {
                labels: timestamps,
                datasets: [{
                    fill: false,
                    tension: 0.3,
                    borderColor: '#848484',
                    borderWidth: 2,
                    pointBorderColor: '#848484',
                    pointBackgroundColor: '#848484',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#848484',
                    pointHoverBorderColor: '#848484',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    data: direction.map((y, i) => ({ x: timestamps[i], y: y || 0 })),
                    showLine: true,
                    label: "Wind Direction"
                }]
            };
        } catch (error) {
            console.error('Error generating wind direction data:', error);
            return EMPTY_CHART_DATA;
        }
    }

    getSolarIrradianceData() {
        const { summary } = this.props;
        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            return EMPTY_CHART_DATA;
        }

        try {
            const irradiance = getFilteredData(summary, 'SLR') || [];
            const timestamps = summary.map(item => item.UTC);
            if (!timestamps.length) return EMPTY_CHART_DATA;

            return {
                labels: timestamps,
                datasets: [{
                    fill: true,
                    tension: 0.3,
                    borderColor: 'transparent',
                    borderWidth: 1,
                    backgroundColor: "rgba(245, 194, 100, 0.8)",
                    pointBorderColor: '#F5C264',
                    pointBackgroundColor: '#F5C264',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#F5C264',
                    pointHoverBorderColor: '#F5C264',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    data: irradiance.map((y, i) => ({ x: timestamps[i], y: y || 0 })),
                    showLine: true,
                    label: "Solar Irradiance"
                }]
            };
        } catch (error) {
            console.error('Error generating solar irradiance data:', error);
            return EMPTY_CHART_DATA;
        }
    }

    getPressureData() {
        const { summary } = this.props;
        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            return EMPTY_CHART_DATA;
        }

        try {
            const pressure = getFilteredData(summary, 'QFE') || [];
            const timestamps = summary.map(item => item.UTC);
            if (!timestamps.length) return EMPTY_CHART_DATA;

            return {
                labels: timestamps,
                datasets: [{
                    fill: false,
                    tension: 0.3,
                    borderColor: '#848484',
                    borderWidth: 3,
                    pointBorderColor: '#848484',
                    pointBackgroundColor: '#848484',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#848484',
                    pointHoverBorderColor: '#848484',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    data: pressure.map((y, i) => ({ x: timestamps[i], y: y || 0 })),
                    showLine: true,
                    label: "Pressure"
                }]
            };
        } catch (error) {
            console.error('Error generating pressure data:', error);
            return EMPTY_CHART_DATA;
        }
    }

    getRainData() {
        const { summary } = this.props;
        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            return EMPTY_CHART_DATA;
        }

        try {
            const rain = getFilteredData(summary, 'RI') || [];
            const timestamps = summary.map(item => item.UTC);
            if (!timestamps.length) return EMPTY_CHART_DATA;

            return {
                labels: timestamps,
                datasets: [{
                    fill: true,
                    tension: 0.3,
                    borderColor: "rgb(15, 82, 186, 0.7)",
                    backgroundColor: "rgb(15, 82, 186, 0.7)",
                    borderWidth: 3,
                    pointBorderColor: '#0F52BA',
                    pointBackgroundColor: '#0F52BA',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#0F52BA',
                    pointHoverBorderColor: '#0F52BA',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    data: rain.map((y, i) => ({ x: timestamps[i], y: y || 0 })),
                    showLine: true,
                    label: "Rainfall"
                }]
            };
        } catch (error) {
            console.error('Error generating rainfall data:', error);
            return EMPTY_CHART_DATA;
        }
    }

    getWindSpeedData() {
        const { summary } = this.props;
        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            return EMPTY_CHART_DATA;
        }

        try {
            const average = getFilteredData(summary, 'S') || [];
            const max = getFilteredData(summary, 'SX') || [];
            const timestamps = summary.map(item => item.UTC);
            if (!timestamps.length) return EMPTY_CHART_DATA;

            const canvas = document.createElement('canvas');
            const gradient = getWindSpeedGradient(canvas, average, max);

            return {
                labels: timestamps,
                datasets: [{
                    fill: false,
                    tension: 0.3,
                    borderColor: '#505050',
                    borderDash: [10,5],
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    data: max.map((y, i) => ({ x: timestamps[i], y: y || 0 })),
                    showLine: true,
                    label: "10 minute Gust speed"
                },{
                    fill: true,
                    tension: 0.3,
                    borderColor: 'transparent',
                    borderWidth: 1,
                    backgroundColor: gradient,
                    pointBorderColor: '#1481e0',
                    pointBackgroundColor: '#1481e0',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#1481e0',
                    pointHoverBorderColor: '#1481e0',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    data: average.map((y, i) => ({ x: timestamps[i], y: y || 0 })),
                    showLine: true,
                    label: "10 minute Average speed"
                }]
            };
        } catch (error) {
            console.error('Error generating wind speed data:', error);
            return EMPTY_CHART_DATA;
        }
    }

    render() {
        const { summary } = this.props;
        const { isLoading, chartData } = this.state;

        if (isLoading) {
            return <Preloader/>;
        }

        if (!summary || !Array.isArray(summary) || summary.length === 0) {
            return <Preloader/>;
        }

        const commonTimeScale = {
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'H:mm' },
                tooltipFormat: 'H:mm'
            },
            display: true,
            ticks: { font: { size: 12 } }
        };

        const WindSpeedOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: commonTimeScale,
                y: {
                    beginAtZero: true,
                    ticks: {
                        font: { size: 12 },
                        stepSize: 5
                    },
                    title: {
                        display: true,
                        text: 'Wind Speed (Knots)'
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top'
                }
            }
        };

        const WindDirectionOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: commonTimeScale,
                y: {
                    min: -180,
                    max: 360,
                    ticks: {
                        font: { size: 12 },
                        stepSize: 90,
                        callback: value => evaluateCardinalDirection(value)
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top'
                }
            }
        };

        const SolarIrradianceOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: commonTimeScale,
                y: {
                    beginAtZero: true,
                    ticks: {
                        font: { size: 12 }
                    },
                    title: {
                        display: true,
                        text: 'W/m2'
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top'
                }
            }
        };

        const PressureOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: commonTimeScale,
                y: {
                    ticks: {
                        font: { size: 12 }
                    },
                    title: {
                        display: true,
                        text: 'hPa'
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top'
                }
            }
        };

        const RainOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: commonTimeScale,
                y: {
                    beginAtZero: true,
                    ticks: {
                        font: { size: 12 },
                        stepSize: 5
                    },
                    title: {
                        display: true,
                        text: 'mm/10min'
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top'
                }
            }
        };

        const TempHumidityOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: commonTimeScale,
                y1: {
                    type: 'linear',
                    position: 'left',
                    title: {
                        display: true,
                        text: 'Temperature'
                    },
                    ticks: {
                        font: { size: 12 },
                        stepSize: 5
                    }
                },
                y2: {
                    type: 'linear',
                    position: 'right',
                    title: {
                        display: true,
                        text: 'Humidity %'
                    },
                    ticks: {
                        font: { size: 12 },
                        stepSize: 20,
                        min: 0,
                        max: 100
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top'
                }
            }
        };

        if (summary[summary.length - 1]?.D > 270 || summary[summary.length - 1]?.D < 90) {
            WindDirectionOptions.scales.y.min = -180;
            WindDirectionOptions.scales.y.max = 180;
        }

        return (
            <div>
                <Card>
                    <Card.Header>
                        <Card.Title as="h2">Temperature and Relative Humidity</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div id="mini-graph" style={{height: '200px'}}>
                            <Line data={chartData.tempHumidity} options={TempHumidityOptions}/>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Card.Title as="h2">Wind</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div id="mini-graph" style={{height: '200px'}}>
                            <Line data={chartData.windSpeed} options={WindSpeedOptions}/>
                        </div>
                        <div id="mini-graph" style={{height: '200px'}}>
                            <Scatter data={chartData.windDirection} options={WindDirectionOptions}/>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Card.Title as="h2">Solar Irradiance</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div id="mini-graph" style={{height: '200px'}}>
                            <Scatter data={chartData.solarIrradiance} options={SolarIrradianceOptions}/>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Card.Title as="h2">Rain</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div id="mini-graph" style={{height: '200px'}}>
                            <Scatter data={chartData.rain} options={RainOptions}/>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Card.Title as="h2">Pressure</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div id="mini-graph" style={{height: '200px'}}>
                            <Scatter data={chartData.pressure} options={PressureOptions}/>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

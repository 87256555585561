import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { disableBodyScroll } from 'body-scroll-lock';
import logo from '../assets/apple-touch-icon-white-57x57.png'
import './Header.css';

export default class Header extends React.Component {
    componentDidMount() {
        disableBodyScroll(document.getElementById('main-header'));
    }

    render = () => {
        return (
            <Navbar id="main-header">
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <img 
                            src={logo} 
                            alt="Weather Watcher Logo" 
                        />
                        <span>Weather Watcher</span>
                    </Navbar.Brand>
                    <Nav>
                        <Nav.Link as={Link} to="/about">About</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        )
    }
}
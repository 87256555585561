import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Add future flags configuration
const routerConfig = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
};

import Layout from './container/Layout';
import StationLayout from './container/StationLayout';
import WidgetLayout from './container/WidgetLayout';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import About from './components/About';
import Callback from './container/Callback';
import _404 from './container/404';
import Logout from './container/Logout';
import IEBrowserDetected from './components/IEBrowserDetected';
import { detect } from 'detect-browser';

const browser = detect();

const NonIEBrowserRoute = ({ element: Component, ...props }) => {
  return (browser.name !== 'ie') ? <Component {...props} /> : <IEBrowserDetected />;
};

export default class AppRoutes extends Component {
  render() {
    return (
      <Router {...routerConfig}>
        <Routes>
          <Route path="/" element={<NonIEBrowserRoute element={Layout} />} />
          <Route path="/station/:id/:type" element={<StationLayout />} />
          <Route path="/widget/:id" element={<WidgetLayout />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsofservice" element={<TermsOfService />} />
          <Route path="/about" element={<About />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<_404 />} />
        </Routes>
      </Router>
    );
  }
}

import React, { Component } from 'react';
import Header from './Header';
import './About.css';

export default class About extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="about-container">
                    <div className="about-section">
                        <h1>About Weather Watcher</h1>
                        <p>
                            I'm a kitesurfer on the Mornington Peninsula and was frustrated that although there were heaps of good forecast sites, 
                            getting actual live observations was a pain. The BOM stations were ok but not great, and there were little weather stations 
                            in different locations (primarily yacht clubs) that were hard to easily find/access on mobile.
                        </p>
                        <p>
                            I hence built this site to pull in the data from BOM, yacht clubs, vicports and other sources. Once I had the initial site 
                            working I found some locations were missing weather stations so I've built my own 4G/Solar stations that can easily be put up.
                        </p>
                        <p>
                            So far I don't have much of a plan on it, at some point I may put up some minimal sponsorship/advertising to help pay for 
                            running costs etc.
                        </p>
                    </div>

                    <div className="about-section">
                        <h3>Feedback</h3>
                        <p>
                            I love feedback so please email me at{' '}
                            <a href="mailto:Andrew.Thomas@weatherwatcher.com.au" target="_top">
                                Andrew.Thomas@weatherwatcher.com.au
                            </a>{' '}
                            with your ideas.
                        </p>
                    </div>

                    <div className="about-section">
                        <h3>Extra Stations</h3>
                        <p>
                            <strong>Existing Stations:</strong> If there is anyone already running a good weather station you'd like to see on the map, 
                            send me a link.
                        </p>
                        <p>
                            <strong>New Solar/4G Stations:</strong> If you have a location in mind that needs a solar/4G station then email me above. 
                            Preferably you already have access or a contact. Note if the location has WiFi/power it does make it cheaper.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
